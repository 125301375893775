import { computed, ref } from "vue"
import { trackEvent } from "@/analytics"
import { RouteName, RouterBuilder } from "@/router/router-builder"
import {NewOnboardingFeatureEvents} from "@/analytics/object-action-tracking"
import { defineStore } from "pinia"
import router from '@/router'

const defaultState = {
  prometheusSelected: true,
  helpRobusta: true,
  slackChannel: undefined as string | null | undefined,
  slackIntegrated: null as null | boolean,
  teamsIntegrated: null as null | boolean,
  configDownloaded: false
}

// This store is defined here to utilize the useRouter composable, which is only accessible within components.
// To maintain compatibility with the existing onboarding flow, we replace activeComponent with router.push.
// This approach allows us to use the router in a store, which wouldn't be possible with regular functions.
export const useOnBoardingStore = defineStore("on-boarding-v2", () => {
  const availableSteps = ref([RouteName.AddClusterConfigure])
  const skipSetupVerification = ref<boolean>(false)
  const clusterName = ref<string>('')

  const state = ref<typeof defaultState>({ ...defaultState })

  const slackKey = ref('')
  const getSlackKey = computed(() => slackKey.value)
  function setSlackKey(key: string) {
    slackKey.value = key
  }

  const slackWorkspace = ref('')
  const getSlackWorkspace = computed(() => slackWorkspace.value)
  function setSlackWorkspace(workspace: string) {
    slackWorkspace.value = workspace
  }

  const teamsUrl = ref('')
  const getTeamsUrl = computed(() => teamsUrl.value)
  function setTeamsUrl(url: string) {
    teamsUrl.value = url
  }

  function resetIntegrations() {
    Object.assign(state.value, defaultState)
    clusterName.value = ''
  }

  const isPrometheusSelected = computed(() => state.value.prometheusSelected)
  function setPrometheusSelected(prometheusSelected: boolean) {
    state.value.prometheusSelected = prometheusSelected
  }

  const getSlackChannel = computed(() => state.value.slackChannel)
  function setSlackChannel(slackChannel?: string | null) {
    state.value.slackChannel = slackChannel
  }

  const isSlackIntegrated = computed(() => state.value.slackIntegrated)
  function setSlackIntegrated(slackIntegrated: boolean | null) {
    state.value.slackIntegrated = slackIntegrated
  }

  const isTeamsIntegrated = computed(() => state.value.teamsIntegrated)
  function setTeamsIntegrated(teamsIntegrated: boolean | null) {
    state.value.teamsIntegrated = teamsIntegrated
  }

  const getClusterName = computed(() => clusterName.value)
  function setClusterName(name: string) {
    clusterName.value = name
  }

  function goToCreateAccount() {
    router.push(RouterBuilder.getCreateAccountRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.CreateAccount })
  }

  function goToCreateCluster() {
    router.push(RouterBuilder.getAddClusterRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.AddClusterConfigure })
  }

  function goToInstall() {
    router.push(RouterBuilder.getAddClusterInstallRobustaRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.AddClusterInstallRobusta })
  }

  function goToPromAlert() {
    router.push(RouterBuilder.getAddClusterPromAlertManagerRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.AddClusterPrometheusAndAlertManager })
  }

  function goToVerifyInstallation() {
    router.push(RouterBuilder.getAddClusterVerifyRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.AddClusterVerifyInstallation })
  }

  function goToApps() {
    router.push(RouterBuilder.getAppsRoute()).catch(() => {})
    trackOnboarding(NewOnboardingFeatureEvents.PageView, { page: RouteName.Invitation })
  }

  function addAvailableStep (
    stepName: RouteName.AddClusterInstallRobusta
      | RouteName.AddClusterVerifyInstallation
      | RouteName.AddClusterPrometheusAndAlertManager
  ) {
    if (!availableSteps.value.includes(stepName)) {
      availableSteps.value.push(stepName)
    }
  }

  function resetAvailableSteps () {
    availableSteps.value = [RouteName.AddClusterConfigure]
  }

  function trackOnboarding(event: string, props?: Record<string, any>) {
    trackEvent(event, {
      ...(props || {}),
    })
  }

  function handleBeforeUnload (event) {
    event.preventDefault()
    event.returnValue = ''
  }

  function startUnloadEvent () {
    window.addEventListener('beforeunload', handleBeforeUnload)
  }
  function endUnloadEvent () {
    window.removeEventListener('beforeunload', handleBeforeUnload)
  }

  return {
    goToCreateAccount,
    goToInstall,
    goToPromAlert,
    goToVerifyInstallation,
    goToCreateCluster,
    goToApps,
    skipSetupVerification,
    trackOnboarding,
    isPrometheusSelected,
    setPrometheusSelected,
    getSlackChannel,
    setSlackChannel,
    isSlackIntegrated,
    setSlackIntegrated,
    isTeamsIntegrated,
    setTeamsIntegrated,
    resetIntegrations,
    getSlackKey,
    setSlackKey,
    getSlackWorkspace,
    setSlackWorkspace,
    getTeamsUrl,
    setTeamsUrl,
    state,
    getClusterName,
    setClusterName,
    availableSteps,
    addAvailableStep,
    resetAvailableSteps,
    startUnloadEvent,
    endUnloadEvent
  }
})
